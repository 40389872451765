.product {
	width: 100%;
}

#productHeader {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 30px;
}

.productTitle {
	font-family: "GothamBold";
}

.productSubTitle > * {
	display: inline;
}

.productSubTitlePrefix {
	font-family: "GothamBook";
	font-style: italic;
}

.productImgContainer {
	width: 100%;
	display: flex;
	justify-content: center;
}

.productImg {
	width: 500px;
	max-width: 100%;
}
