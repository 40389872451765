.postRow {
	margin-bottom: 20px;
}

.postImg {
	max-width: 100%;
}

#postAuthorSection {
	white-space: pre-line;
}
#postAuthorContainer {
	padding: 30px;
	background-color: #002279;
	color: #fff;
}
#authorImgContainer {
	background-color: #ddd;
	width: 8em;
	height: 8em;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
#authorImg {
	width: 3em;
}
#authorTitle {
	margin-top: 30px;
	margin-bottom: 30px;
	text-align: center;
	font-family: GothamMedium;
}
#authorText {
	text-align: justify;
}
