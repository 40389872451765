.headerSelectItem {
	text-align: center;
}

.headerSelectItemGlobal {
	padding-right: 4% !important;
}

.headerSelectItemHome {
	padding-right: 6% !important;
}

.headerChevron {
	position: absolute;
}
