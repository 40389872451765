.button {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 19px;
	cursor: pointer;
	text-align: center;
	width: fit-content;
}

.light {
	background-color: #000;
	color: #fff;
}
.dark {
	background-color: #f4fefe;
	color: #000;
}
.blue {
	background-color: #002279;
	color: #fff;
}
