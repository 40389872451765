#footer {
	background-color: #000;
	color: #fff;
	text-align: center;
	padding: 50px 40px 100px 40px;
}

#footerImgContainer {
	display: flex;
	justify-content: center;
}
#footerImg {
	margin-bottom: 20px;
}

#footerMenu {
	margin-bottom: 40px;
	font-family: "GothamLight";
}
#footerMenu > * {
	display: inline-block;
	margin: 0px 10px;
}

#infoMail {
	margin-bottom: 80px;
	font-family: "GothamBold";
}

#rights {
	font-family: "GothamLight";
}

.redirectButtonContainer {
	position: relative;
}
.loginRedirectButton {
	position: absolute;
	bottom: -70px;
	right: 0px;
	z-index: 1;
}
.adminRedirectButton {
	position: absolute;
	bottom: -70px;
	right: 150px;
	z-index: 1;
}
