#homeHeader {
	width: 100%;
	height: 100vh;
	background-position: center;
	background-size: 1920px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
#homeHeader > * {
	display: flex;
	justify-content: center;
	align-items: center;
}

#homeHeaderTop {
	margin-bottom: 30px;
}

#homeHeaderTopImg {
	max-width: 100%;
	max-height: 100px;
}

#homeHeaderCenter {
	max-width: 90%;
	margin: 0 auto;
}

.homeHeaderSection {
	margin: 0 1%;
	cursor: pointer;
	text-align: center;
	padding: 5px 1% 5px 1%;
}
.homeHeaderSection:hover {
	border-bottom: 1px solid #fff;
}
.homeHeaderSection > * {
	display: inline;
}
