.formEmbed {
	display: block;
	width: 300px;
	height: 150px;
	margin-top: 10px;
}

.formSelect {
	width: 150px;
}

.formNbInput {
	width: 70px;
}
