#contactContent {
	padding: 50px 0px 70px 0px;
}

#contactForm {
	position: relative;
	width: 100%;
	margin-bottom: 30px;
}

#contactTitle {
	text-align: center;
	margin-bottom: 50px;
	font-family: "GothamBold";
}

#contactRow {
	margin-bottom: 50px;
	font-family: "cardinalphoto-regular";
}

.smallInput {
	width: 45%;
}

.contactEmailTextField {
	position: absolute !important;
	right: 0;
}

.contactMessageTextField {
	margin-bottom: 50px !important;
}

.contactButton {
	margin: 0 auto;
	width: 250px;
	height: 50px;
	border-radius: 45px !important;
	font-family: "GothamMedium";
}

#sliderContainer {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 40px;
	height: 40px;
}
