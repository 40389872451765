#adminPageContainer {
	height: 100%;
}

.adminPageBackButton {
	position: absolute;
	top: 13px;
	left: 50px;
	z-index: 1;
}

#adminPageContent {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-left: 15%;
	padding-right: 15%;
}

.adminPageSection {
	flex: 1;
	overflow: hidden;
	font-size: 13px;
}
