#resources {
	position: relative;
}

#resourcesHeader {
	text-align: center;
	margin-bottom: 50px;
}

#resourcesTitle {
	margin-bottom: 20px;
	font-family: "GothamMedium";
}

.resourcesSection {
	margin-bottom: 40px;
}

.resourcesSectionTitle {
	margin-bottom: 20px;
	font-family: "GothamMedium";
}

.resourcesSectionPreviews {
	position: relative;
	display: grid;
	gap: 30px;
}
