#languages {
	border: 1px solid #eee;
	border-radius: 7px;
	padding: 15px;
}

#languagesList {
	margin-bottom: 30px;
}

.language {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 70px 140px 50px;
	border-bottom: 1px solid #eee;
}
.language > * {
	display: inline-block;
}

.languageCode {
	margin-left: 5px;
}

.languageDeleteButton {
	padding-top: 2px;
}

#addLanguageForm {
	display: grid;
	grid-template-columns: 200px 200px;
	grid-column-gap: 20px;
	grid-row-gap: 10px;
	margin-bottom: 30px;
}
