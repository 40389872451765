#login {
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

#loginForm {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	row-gap: 30px;
	width: 100%;
	height: 100%;
}

.loginFormTitle {
	margin-bottom: 10px;
}

.loginFormInput {
	height: 25px;
	padding: 0px 5px 0px 5px;
}

.loginButton {
	margin-top: 10px;
}
