.managementButtonContainer {
	position: relative;
	height: 10px;
	margin-top: 8px;
}

.managementButton {
	position: absolute;
	right: 15px;
}
