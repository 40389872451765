.resourcePreview {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background-color: #f5f7fa;
	border: 1px solid #000;
}

.resourcePreviewImg {
	width: 80%;
	padding: 10%;
}

.resourcePreviewContent {
	padding: 10px 20px 20px 20px;
	border-top: 1px solid #002279;
}

.resourcePreviewTitle {
	margin-bottom: 10px;
	font-size: 1em;
	font-weight: 600 !important;
}

.resourceText {
	font-size: 13px;
}

.resourcePreviewButtonContainer {
	position: relative;
	flex-grow: 1;
	display: flex;
	justify-content: center;
	min-height: 25px;
	margin-bottom: 20px;
	font-family: "GothamBold";
}

.openPdfButton {
	position: absolute;
	bottom: 0;
}
