#mobileHeader {
	width: 100%;
	height: 5em;
	display: flex;
	background-color: #002279;
}
#mobileHeaderLeft {
	width: 25%;
	display: flex;
	align-items: center;
	justify-content: center;
}
#mobileHeaderLeftImg {
	max-width: 100%;
	max-height: 25px;
	position: relative;
	top: 2px;
}
#mobileHeaderCenter {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
#mobileHeaderImg {
	height: 1.5em;
}
#mobileHeaderRight {
	width: 25%;
	display: flex;
	align-items: center;
}
.mobileHeaderRightButton {
	position: absolute;
	right: 20px;
	font-size: 30px;
	color: #fff;
}

#mobileHeaderMenu {
	position: absolute;
	right: 0;
	height: 100%;
	z-index: 1;
	background-color: white;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
#mobileHeaderMenuImgContainer {
	height: 5em;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
#mobileHeaderMenuImg {
	height: 1.5em;
	margin: 15px 10px 25px 10px;
}
#mobileHeaderMenuSectionsContainer {
	text-align: center;
	margin-top: 15px;
	margin-bottom: 40px;
	padding: 0px 30px 0px 30px;
}
.section {
	color: #002279;
	margin-bottom: 20px;
	padding-bottom: 5px;
}
#mobileHeaderMenuButtonContainer {
	height: 5em;
	text-align: center;
	background-color: white;
	font-size: 30px;
}
.closeButton {
	color: #002279;
}
