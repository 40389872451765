#header {
	width: 100%;
	height: 5em;
	display: flex;
	background-color: #002279;
	color: #fff;
}
#header > * {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#headerLeft {
	width: 25%;
}

#headerLeftImg {
	max-width: 100%;
	max-height: 25px;
}

#headerCenter {
	width: 50%;
	max-width: 90%;
	margin: 0 auto;
}

.headerSection {
	margin: 0 1%;
	cursor: pointer;
	text-align: center;
	padding: 5px 1% 5px 1%;
}
.headerSection:hover {
	border-bottom: 1px solid #fff;
}
.headerSection > * {
	display: inline;
}

#headerRight {
	width: 25%;
}

#headerRightImg {
	height: 25px;
}
