.postPreview {
	margin-bottom: 30px;
}

.postPreviewImg {
	width: 100%;
}

.postPreviewContent {
	padding: 20px;
}

.postPreviewDate {
	margin-bottom: 15px;
}

.postPreviewTitle {
	margin-bottom: 5px;
}

.postPreviewText {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.postPreviewButtonContainer {
	display: flex;
	justify-content: center;
	margin-top: 15px;
}
