#translations {
	display: flex;
	flex-direction: column;
	height: 100%;
}

#addTranslation {
	margin-bottom: 40px;
}

#translationsList {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	border: 1px solid #bbb;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 20px;
}

#translationsRows {
	flex: 1;
	overflow-y: scroll;
}

.translationsGrid {
	display: grid;
}

.translationsTitles {
	margin-bottom: 10px;
}

.keyTitle {
	font-weight: bold;
}

.addTranslationButton {
	margin-top: 30px;
}

.translationDeleteButtonContainer {
	display: flex;
	align-items: center;
	padding-left: 15px;
}
