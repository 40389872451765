#blogContainer {
	position: absolute;
	width: 100%;
	height: 100%;
}

.addPostButton {
	position: absolute;
	top: 15px;
	right: 15px;
}

.postsColumn {
	position: relative;
	display: inline-block;
	margin: 15px;
}

#pagesButtons {
	position: absolute;
	right: 15px;
	display: flex;
}
#pagesButtons > * {
	display: inline-block;
}
