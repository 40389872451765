.textEditorToolbar {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.textEditorToolbar > * {
	display: inline-block;
	margin-right: 5px;
}

.textEditor {
	width: 100%;
	margin-top: 5px;
}

/* .public-DraftEditor-content {
	min-height: 50px;
} */
