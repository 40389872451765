#mobileHomeHeader {
	height: 100vh;
	background-position: center;
	background-size: 1500px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#mobileHomeHeaderTop {
	display: flex;
	justify-content: center;
	margin-bottom: 50px;
}

#mobileHomeHeaderTopImg {
	max-width: 80%;
	max-height: 5em;
}

.mobileHomeHeaderSection {
	margin: 0 2.5em;
	cursor: pointer;
	text-align: center;
	padding: 15px;
}
.mobileHomeHeaderSection:hover {
	border-bottom: 1px solid #fff;
}

#mobileHomeHeaderChevron {
	font-size: 30px;
	margin-top: 30px;
}
