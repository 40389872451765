#home {
	position: relative;
	white-space: pre-line;
	overflow-x: hidden;
}

.homeContent {
	margin-left: 15%;
	margin-right: 15%;
	padding-top: 20px;
	padding-bottom: 20px;
}
.homeSection {
	margin-top: 50px;
	padding-bottom: 70px;
}
.homeSectionTitleContainer {
	display: grid;
	margin-bottom: 20px;
}
.homeSectionTitleContainer > * {
	display: flex;
	align-items: center;
}
.homeSectionTitle {
	align-items: flex-start;
	flex-direction: column;
}
.homeSectionTitlePrefix {
	width: 100%;
	font-family: "cardinalphoto-regular";
}
.homeSectionTitleValue {
	width: 100%;
	font-family: "GothamBold";
	margin-top: -0.1em;
	line-height: 0.9em;
}
.homeSectionImgContainer {
	margin-left: auto;
}
.homeSectionImg {
	height: 70px;
}
.homeSectionContent {
	text-align: justify;
}

#homeSubParts {
	display: grid;
}
.homeSubPartTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding-top: 60px;
	padding-bottom: 30px;
}
.homeSubPartMainTitle {
	font-family: "GothamBold";
	letter-spacing: -3px;
}
.homeSubPartMainSubTitle {
	font-family: "GothamBook";
}
.homeSubPartMainImg {
	max-width: 90%;
}
.homeSubPartContent {
	margin-bottom: 30px;
}
.homeSubPartMain {
	margin-bottom: 10px;
}
.homeSubPartMain {
	display: flex;
}
.homeSubPartImg {
	height: 20px;
	margin-right: 10px;
	position: relative;
	top: 3px;
}
.homeSubPartMainText {
	font-family: "GothamMedium";
}
.homeSubPartText {
	text-align: justify;
	padding-left: 30px;
}

#homeThridPart {
	text-align: center;
	padding: 60px;
}
#homeThridPartTitle {
	font-family: GothamBold;
}
.homeThridPartProduct {
	background-color: #ddd;
	margin: 0 auto;
	max-width: 80%;
	height: 50px;
	margin-top: 40px;
	display: flex;
	padding-left: 15px;
	font-family: "cardinalphoto-regular";
}
.homeThridPartProductImg {
	margin: 0 auto;
	margin-top: 40px;
	display: flex;
	max-width: 80%;
}

.homeContactButton {
	position: fixed;
	top: 50%;
	left: -42px;
	width: 100px;
	transform: rotate(-90deg);
	text-transform: uppercase;
	font-family: "GothamBold";
}
