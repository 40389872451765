@font-face {
	font-family: GothamBook;
	src: url("./assets/font/Gotham/Gotham-Book.otf") format("opentype");
}
@font-face {
	font-family: GothamMedium;
	src: url("./assets/font/Gotham/Gotham-Medium.otf") format("opentype");
}
@font-face {
	font-family: GothamLight;
	src: url("./assets/font/Gotham/Gotham-Light.otf") format("opentype");
}
@font-face {
	font-family: GothamBold;
	src: url("./assets/font/Gotham/Gotham-Bold.otf") format("opentype");
}
@font-face {
	font-family: "cardinalphoto-regular";
	src: url("./assets/font/CardinalPhoto/cardinalphotoweb-regular.woff2") format("woff2"),
		url("./assets/font/CardinalPhoto/cardinalphotoweb-regular.woff") format("woff");
}

html,
body,
#root {
	width: 100%;
	height: 100%;
}
#app {
	width: 100%;
	height: 100%;
	word-wrap: break-word;
	font-family: "GothamLight";
}

::-webkit-scrollbar {
	height: 7px;
	width: 10px;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb {
	background: #888888;
	border-radius: 10px;
}
@media (hover: hover) and (pointer: fine) {
	::-webkit-scrollbar-thumb:hover {
		background: #555555;
	}
}

.bigTitle {
	font-size: 3em;
}
.title {
	font-size: 2em;
}
.subTitle {
	font-size: 1.25em;
}
.smallText {
	font-size: 0.75em;
}

.iconButton {
	font-size: 25px;
	color: #000;
}
.iconButton:hover {
	color: #aaa;
}

.pointer {
	cursor: pointer;
}
.border {
	border: 1px solid #ccc;
}
.borderRadius {
	border-radius: 4px;
}
.borderRadius2 {
	border-radius: 8px;
}
.borderRadius3 {
	border-radius: 12px;
}
.borderRadius4 {
	border-radius: 45px !important;
}

.blackBackground {
	background: #000 !important;
	color: #fff !important;
}
.blueBackground {
	background-color: #002279 !important;
	color: #fff !important;
}

.container {
	position: relative;
	top: 0;
	width: 100%;
}
.content {
	margin-left: 15%;
	margin-right: 15%;
	padding-bottom: 40px;
}

.backButton {
	position: relative;
	left: 15%;
	margin-top: 15px;
	margin-bottom: 15px;
}

.form {
	width: 100%;
}
.formRow {
	margin-bottom: 15px;
}
.formTitle {
	margin-bottom: 10px;
}
.formHiddenInputFile {
	display: none;
}
.formImg {
	display: block;
	max-height: 80px;
	margin-top: 10px;
}
.formButton {
	position: relative;
	top: 20px;
	margin: auto;
}
